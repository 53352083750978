import { type ReactElement, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Modal, { ModalContent } from 'refreshed-component/molecules/Modal';
import { Pagination } from 'refreshed-component/molecules/Pagination';
import { ProjectCard } from 'refreshed-pages/project-exchange/components/Marketplace/components/ProjectsList/components/ProjectCard';
import type { Project } from 'refreshed-pages/project-exchange/components/Marketplace/utils/Project';
import { toMarketplaceApiFilters } from 'refreshed-pages/project-exchange/components/Marketplace/utils/toMarketplaceApiParams';
import { toResults } from 'refreshed-pages/project-exchange/components/Marketplace/utils/toResults';
import type { ProjectsListFilters } from 'refreshed-pages/project-exchange/components/MyProjects/components/ProjectsList';
import { SearchAndFilter } from 'refreshed-pages/project-exchange/components/SearchAndFilter';
import { useProjectsListFilteringOptions } from 'refreshed-pages/project-exchange/hooks/useProjectsListFilteringOptions';
import { useSearchResultsPage } from 'refreshed-pages/project-exchange/hooks/useSearchResultsPage';
import { toFiltersFromQueryParams } from 'refreshed-pages/project-exchange/utils/toFiltersFromQueryParams';
import { toQueryParamsFromFilters } from 'refreshed-pages/project-exchange/utils/toQueryParamsFromFilters';
import styled from 'styled-components';

import { AssetCategory } from '@aircarbon/utils-common';
import type { ApxProject } from '@aircarbon/utils-common/src/dto';

export const MarketplaceModal: React.FC<{
  action: ReactElement;
}> = ({ action }) => {
  const history = useHistory();
  const {
    filteringOptions,
    isFetching: isFetchingFilteringOptions,
    fetchFilteringOptions,
  } = useProjectsListFilteringOptions();

  useEffect(() => {
    fetchFilteringOptions();
  }, [fetchFilteringOptions]);

  const {
    totalResultsCount: totalProjectsCount,
    isFetching: isFetchingProjects,
    results: projects,
    searchValue,
    currentPage,
    pageSize,
    filters,
    changePagination,
    search,
    filter,
  } = useSearchResultsPage<ProjectsListFilters, ApxProject, Project>({
    // TODO: Migrate to properly fetcher with api client
    searchEndpoint: '/user/apx/projects',
    toQueryParams: toQueryParamsFromFilters,
    toApiFilters: toMarketplaceApiFilters,
    toFilters: toFiltersFromQueryParams,
    toResults,
  });

  const onFilter = (filters: ProjectsListFilters) => {
    filter(filters);
  };

  const onSearch = (searchValue: string) => {
    search(searchValue);
  };

  const onChangePagination = (currentPage: number, pageSize: number) => {
    changePagination(currentPage, pageSize);
  };

  return (
    <StyledMarketplaceModal title="Select Project to Trade" action={action}>
      {({ onClose }) => {
        return (
          <StyledModalContent>
            <SearchAndFilter
              areFilteringOptionsLoading={isFetchingFilteringOptions}
              filteringOptions={filteringOptions}
              onSearch={onSearch}
              onChange={onFilter}
              filters={filters}
              search={searchValue}
            />
            {!isFetchingProjects &&
              projects.map((project) => (
                <ProjectCard
                  onPressTrade={() => {
                    onClose();
                    history.push(`/account/trade?assetCategoryId=${AssetCategory.fct}&projectId=${project.id}`);
                  }}
                  onPressDetails={() => {
                    onClose();
                    history.push(`/account/apx/marketplace/${project.id}`);
                  }}
                  {...project}
                />
              ))}
            <Pagination
              total={totalProjectsCount}
              page={currentPage}
              pageSize={pageSize}
              onChange={onChangePagination}
            />
          </StyledModalContent>
        );
      }}
    </StyledMarketplaceModal>
  );
};

const StyledMarketplaceModal = styled(Modal)`
  > .modal {
    max-width: 1241px;
    width: 100%;
  }
`;

const StyledModalContent = styled(ModalContent)`
  gap: 24px;
`;
