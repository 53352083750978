import { format } from 'date-fns';

import { Badge, type ModalApproveDepositRequestProps } from '@aircarbon/ui';

import type { DepositRequestResource } from './DepositRequestResource';
import { toAssetName } from './toAssetName';
import { toDepositAmount } from './toDepositAmount';
import { toStatusBadgeProps } from './toStatusBadgeProps';

// eslint-disable-next-line import/prefer-default-export
export const toModalApproveDepositRequestProps = (
  request: DepositRequestResource,
): Omit<ModalApproveDepositRequestProps, 'isVisible' | 'isConfirming' | 'onClose' | 'onConfirm'> => ({
  requestId: String(request.id),
  serialBlock: request.serialBlock,
  assetInfo: [
    {
      label: 'Asset',
      description: toAssetName(request),
    },
    {
      label: 'Amount',
      description: toDepositAmount(request),
    },
  ],
  requestInfo: [
    {
      label: 'Status',
      description: <Badge {...toStatusBadgeProps(request)} />,
    },
    {
      label: 'Request Date',
      description: format(new Date(request.createdAtUtc), "MMMM do',' yyyy 'at' h:mm b"),
    },
  ],
  metaInfo: request.project
    ? ([
        request.project.name
          ? {
              label: 'Project',
              description: [request.project.name],
            }
          : null,
        request.project.status
          ? {
              label: 'Project Status',
              description: [request.project.status],
            }
          : null,
        request.project.registryCode
          ? {
              label: 'Registry Code',
              description: request.project.registryCode,
            }
          : null,
        request.project.registryName
          ? {
              label: 'Registry Name',
              description: [request.project.registryName],
            }
          : null,
        request.vintageStartDate && request.vintageEndDate
          ? {
              label: 'Vintage Period',
              description: [
                [
                  format(new Date(request.vintageStartDate), 'dd/MM/yyyy'),
                  format(new Date(request.vintageEndDate), 'dd/MM/yyyy'),
                ].join(' ~ '),
              ],
            }
          : null,
      ].filter((v) => !!v) as Array<{
        label: string;
        description: string | Array<string> | React.ReactElement;
      }>)
    : [],
});
