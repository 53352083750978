import { useRef, useState } from 'react';
import Loading from 'refreshed-component/molecules/Loading';
import { FieldsGroup } from 'refreshed-pages/market-board-v2/components/FieldsGroup';
import onPressDownloadAuctionRules from 'refreshed-pages/market-board-v2/utils/onPressDownloadAuctionRules';

import {
  Button,
  ButtonSize,
  ButtonVariant,
  CMBOrderType,
  CardCMBOrderSummary,
  Divider,
  IconName,
  LabelWithDescription,
  LabelWithDescriptionSize,
  Layer,
  Modal,
  Text,
  TextAs,
  TextColor,
  TypographyVariant,
  styled,
  toSpacing,
  useSpacing,
} from '@aircarbon/ui';
import { type AssetCategory, Const, assetCategoryUom, formatter } from '@aircarbon/utils-common';

import SelectOboAccount, { type AccountDetails } from 'components/SelectOboAccount';

import { Entity, useEntity } from 'state/entity';
import { UI } from 'state/ui';
import { User } from 'state/user';

import { useMarketplaceProduct } from 'hooks/useMarketplaceProduct';

import { useOrderSummary } from '../../hooks/useOrderSummary';
import { CreateNewProjectModal } from '../CreateNewProjectModal';
import { ReviewOrderModal, type ReviewOrderModalRef } from '../ReviewOrderModal';
import { PlaceBidForm, type PlaceBidFormRef } from './components/PlaceBidForm';
import { PlaceOfferForm, type PlaceOfferFormRef } from './components/PlaceOfferForm';
import { type BidFormValue, type OfferFormValue, usePlaceOrderForm } from './hooks/usePlaceOrderForm';
import { toReviewOrderModalData } from './utils/toReviewOrderModalData';
import { toStaticTexts } from './utils/toStaticTexts';

export interface OrderDetails {
  id: string;
  type: CMBOrderType;
  openQuantity: string;
  rawQuantity: number;
  endsAt?: string;
  price: string;
  rawPrice: number;
  minimumQuantity?: number;
  maximumQuantity?: number;
  quantityMultiplesOf?: number;
  priceLabel: string;
  isPrefunded: boolean;
  contractType?: string;
  auctionRulesLink?: string;
  metaFields: [
    {
      label: string;
      value: string;
    },
    ({ label: string; value: string } | undefined)?,
  ][];
}

export const PlaceOrderModal: React.FunctionComponent<{
  orderDetails?: OrderDetails;
  orderCriteria: Array<{
    key: string;
    operator: string;
    values: Array<string>;
  }>;
  isSubmittingOrder: boolean;
  onClose(): void;
  onConfirmOrder(formValue: BidFormValue | OfferFormValue, onBehalfOfUserId: number): void;
}> = (props) => {
  const { orderDetails, orderCriteria, onClose, onConfirmOrder: onConfirmOrderProp, isSubmittingOrder } = props;

  const { spacing } = useSpacing();
  const [isCreateNewProjectModalVisible, setIsCreateNewProjectModalVisible] = useState(false);
  const placeBidFormRef = useRef<PlaceBidFormRef>(null);
  const placeOfferFormRef = useRef<PlaceOfferFormRef>(null);
  const reviewOrderModalRef = useRef<ReviewOrderModalRef>(null);
  const { value, setValue } = usePlaceOrderForm({
    orderType: orderDetails?.type || CMBOrderType.Offer,
  });

  const {
    selector: { getAccountAddress, getUserId, getFullName },
    status: { canCmbBidOboV2 },
  } = User.useContainer();

  const {
    selector: { mainCcyCode, mainCcyNumDecimals },
  } = Entity.useContainer();

  // Used for Members
  const [selectedAccount, setSelectedAccount] = useState<AccountDetails>({
    account: getAccountAddress(),
    userId: getUserId(),
    fullName: getFullName(),
  });

  const { availableBalance, estimatedTotal, fee, isCalculatingFee, isLoadingBalance } = useOrderSummary({
    orderType: orderDetails?.type || CMBOrderType.Offer,
    price: Number(value.price || 0),
    quantity: Number(value.quantity || 0),
    userId: selectedAccount.userId,
    isTradeRequest: true,
  });

  const { product } = useMarketplaceProduct();
  const uom = assetCategoryUom[Number(product) as AssetCategory];
  const { selector } = useEntity();
  const { getSetting } = UI.useContainer();
  const isMarketBoardAndAuctionsV2SettlementModeEnabled =
    getSetting(Const.FeatureToggle.MarketBoardAndAuctionsV2SettlementMode) === '1';

  const currencyCode = selector.mainCcySymbol;
  const onPressCreateNewProject = () => {
    setIsCreateNewProjectModalVisible(true);
  };

  const onCloseCreateNewProjectModal = () => {
    setIsCreateNewProjectModalVisible(false);
  };

  const onPressPlaceOrder = () => {
    let hasErrors = false;

    if (orderDetails?.type === CMBOrderType.Bid) {
      hasErrors = placeOfferFormRef.current?.validate() || hasErrors;
    } else {
      hasErrors = placeBidFormRef.current?.validate() || hasErrors;
    }

    if (hasErrors) {
      return;
    }

    reviewOrderModalRef.current?.show(
      toReviewOrderModalData({
        formValue: value,
        orderDetails: orderDetails as OrderDetails,
        currency: currencyCode,
        quantityUnit: uom,
        total: estimatedTotal,
        fee,
        placedBy: getFullName(),
        onBehalfOf: selectedAccount.fullName,
        orderIsPrefunded: orderDetails?.isPrefunded || false,
        isOtcSettlementEnabled: isMarketBoardAndAuctionsV2SettlementModeEnabled,
      }),
    );
  };

  const onConfirmOrder = () => {
    onConfirmOrderProp(value, selectedAccount.userId);
  };

  const staticTexts = orderDetails
    ? toStaticTexts({
        orderId: orderDetails.id,
        orderType: orderDetails.type,
      })
    : {
        modalTitle: '',
        detailsTitle: '',
        formTitle: '',
      };

  const onNewProjectCreated = (newProject: { id: string; name: string; description: string }) => {
    setValue((prevValue) => ({
      ...prevValue,
      project: {
        title: newProject.name,
        description: newProject.description,
        value: newProject.id,
      },
    }));
    setIsCreateNewProjectModalVisible(false);
  };

  return (
    <>
      <Modal
        footer={
          <Footer>
            <Divider />
            <TotalsAndPlaceOrder>
              <Totals>
                {!isMarketBoardAndAuctionsV2SettlementModeEnabled ? (
                  <LabelWithDescription
                    size={LabelWithDescriptionSize.s}
                    label="Available Balance"
                    description={new Intl.NumberFormat('en-US', {
                      style: 'currency',
                      currency: currencyCode,
                    }).format(availableBalance)}
                  />
                ) : null}
                <LabelWithDescription
                  label="Estimated Total"
                  description={new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: currencyCode,
                  }).format(estimatedTotal)}
                  size={LabelWithDescriptionSize.s}
                  descriptionSuffix={
                    <Text as={TextAs.span} variant={TypographyVariant.caption} color={TextColor.secondary}>
                      {isCalculatingFee
                        ? 'calculating fee...'
                        : `
                        (fee ~ ${new Intl.NumberFormat('en-US', {
                          style: 'currency',
                          currency: currencyCode,
                        }).format(fee)})
                        `}
                    </Text>
                  }
                />
              </Totals>
              <Button
                endIcon={IconName.ArrowEnd}
                variant={ButtonVariant.secondary}
                onPress={onPressPlaceOrder}
                isDisabled={isCalculatingFee}
              >
                Place Order
              </Button>
            </TotalsAndPlaceOrder>
          </Footer>
        }
        title={staticTexts.modalTitle}
        isVisible={!!orderDetails}
        onClose={onClose}
      >
        <Text variant={TypographyVariant.subtitle1}>{staticTexts.detailsTitle}</Text>

        <Layer>
          {orderDetails && (
            <CardCMBOrderSummary
              marginTop={spacing(8)}
              id={orderDetails.id}
              type={orderDetails.type}
              isInstantTrade={orderDetails.isPrefunded}
              contractType={orderDetails.contractType}
              endsAt={orderDetails.endsAt || ''}
              openQuantity={orderDetails.openQuantity}
              price={orderDetails.price}
              priceLabel={orderDetails.priceLabel}
              metaFields={orderDetails.metaFields}
            />
          )}
        </Layer>
        {!!orderDetails?.auctionRulesLink && (
          <Button
            startIcon={IconName.Download}
            onPress={() => onPressDownloadAuctionRules(Number(orderDetails?.id))}
            variant={ButtonVariant.outlined}
            marginTop={spacing(8)}
            size={ButtonSize.s}
          >
            Download Auction Rules
          </Button>
        )}
        <Text marginVertical={spacing(8)} variant={TypographyVariant.subtitle1}>
          {staticTexts.formTitle}
        </Text>
        {canCmbBidOboV2() && (
          <FieldsGroup columnMinWidth="14rem">
            <div className="flex flex-row gap-large">
              <div className="flex flex-col flex-1 gap-2xs">
                <Text>On Behalf Of</Text>
                <SelectOboAccount
                  name="OBO"
                  inputValue={selectedAccount.account}
                  setFieldValue={(_selectedField, value) => {
                    setSelectedAccount({ account: value.value, userId: value.user_id, fullName: value.fullName });
                  }}
                />
                <div className="flex text-xs">
                  <div>Available Balance:</div>
                  <div className="relative pl-2">
                    {isLoadingBalance ? (
                      <Loading size="small" />
                    ) : (
                      <span>
                        {mainCcyCode}
                        {formatter.formatNumber(availableBalance, mainCcyNumDecimals)}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </FieldsGroup>
        )}
        {!!orderDetails &&
          (orderDetails.type === CMBOrderType.Bid ? (
            <PlaceOfferForm
              ref={placeOfferFormRef}
              priceCurrency={currencyCode}
              quantityUnit={uom}
              value={value as OfferFormValue}
              ownedByUserId={selectedAccount.userId}
              onChange={setValue}
              availableVintages={orderCriteria.find((criteria) => criteria.key === 'VINTAGE_YEAR')?.values}
              onPressCreateNewProject={onPressCreateNewProject}
              isInstantTradeEnabled
              selectProjectFilterCriteria={orderCriteria}
              minimumQuantity={orderDetails.minimumQuantity ?? 1000}
              maximumQuantity={orderDetails.maximumQuantity ?? orderDetails.rawQuantity}
              openQuantity={+orderDetails?.openQuantity?.replace(/,/g, '')}
            />
          ) : (
            <PlaceBidForm
              onChange={setValue}
              priceCurrency={currencyCode}
              quantityUnit={uom}
              value={value}
              ref={placeBidFormRef}
              isInstantTradeEnabled={orderDetails.isPrefunded}
              minimumQuantity={orderDetails.minimumQuantity ?? 1000}
              maximumQuantity={orderDetails.maximumQuantity ?? orderDetails.rawQuantity}
              quantityMultiplesOf={orderDetails.quantityMultiplesOf}
              openQuantity={+orderDetails?.openQuantity?.replace(/,/g, '')}
            />
          ))}
      </Modal>
      <CreateNewProjectModal
        onClose={onCloseCreateNewProjectModal}
        isVisible={isCreateNewProjectModalVisible}
        onProjectCreated={onNewProjectCreated}
      />
      {!!orderDetails && (
        <ReviewOrderModal ref={reviewOrderModalRef} isSubmitting={isSubmittingOrder} onConfirm={onConfirmOrder} />
      )}
    </>
  );
};

const Footer = styled.div`
  display: flex;
  flex-direction: column;
`;

const TotalsAndPlaceOrder = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${({ theme }) => toSpacing(theme)(8)};
`;

const Totals = styled.div`
  display: flex;
  gap: ${({ theme }) => toSpacing(theme)(16)};
`;
