import { format } from 'date-fns';
import { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';

import {
  Badge,
  ModalApproveDepositRequest,
  ModalRejectDepositRequest,
  PageDepositRequest,
  ToastVariant,
  showToast,
} from '@aircarbon/ui';
import { Dto } from '@aircarbon/utils-common';

import { UI } from 'state/ui';

import type { DepositRequestResource } from '../DepositRequestsContainer/utils/DepositRequestResource';
import { approveDepositRequest } from '../DepositRequestsContainer/utils/approveDepositRequest';
import { fetchDepositRequest } from '../DepositRequestsContainer/utils/fetchDepositRequest';
import { rejectDepositRequest } from '../DepositRequestsContainer/utils/rejectDepositRequest';
import { toAssetName } from '../DepositRequestsContainer/utils/toAssetName';
import { toDepositAmount } from '../DepositRequestsContainer/utils/toDepositAmount';
import { toStatusBadgeProps } from '../DepositRequestsContainer/utils/toStatusBadgeProps';

export const PageDepositRequestContainer = () => {
  const history = useHistory();
  const { id } = useParams<{
    id: string;
  }>();

  const [isModalApproveRequestVisible, setIsModalApproveRequestVisible] = useState(false);
  const [isModalRejectRequestVisible, setIsModalRejectRequestVisible] = useState(false);
  const [isConfirmingApprove, setIsConfirmingApprove] = useState(false);
  const [rejectionReason, setRejectionReason] = useState('');
  const { data, isLoading, refetch } = useQuery(['depositRequest', id], () =>
    fetchDepositRequest({
      requestId: id,
    }),
  );
  const [isRejecting, setIsRejecting] = useState(false);

  const { getSetting } = UI.useContainer();

  let registryAccountRequisitesMap: Record<
    string,
    {
      registryName: string;
      accountName: string;
      accountType: string;
      accountNumber: string;
    }
  > = {};

  try {
    registryAccountRequisitesMap = JSON.parse(getSetting('web_acx_registry_account_requisites'));
  } catch {}

  const pageProps = useMemo(() => {
    if (!data || data.status !== 'success') {
      return {
        requestId: '',
        serialBlock: '',
      };
    }

    const request = (data as any).data as DepositRequestResource;

    const requisites = request.project ? registryAccountRequisitesMap[request.project.registryCode] : undefined;

    return {
      requestId: String(request.id),
      serialBlock: request.serialBlock,
      assetInfo: [
        {
          label: 'Asset',
          description: toAssetName(request),
        },
        {
          label: 'Amount',
          description: toDepositAmount(request),
        },
      ],
      requisiteInfo: requisites
        ? [
            {
              label: 'Registry Name',
              description: [requisites.registryName],
            },
            {
              label: 'Account Name',
              description: [requisites.accountName],
            },
            {
              label: 'Account Type',
              description: [requisites.accountType],
            },
            {
              label: 'Account Number',
              description: [requisites.accountNumber],
            },
          ]
        : [],
      requestInfo: [
        {
          label: 'Status',
          description: <Badge {...toStatusBadgeProps(request)} />,
        },
        {
          label: 'Request Date',
          description: format(new Date(request.createdAtUtc), "MMMM do',' yyyy 'at' h:mm b"),
        },
        ...([Dto.AssetDepositStatus.Rejected, Dto.AssetDepositStatus.RejectedByUser].includes(request.status) &&
        request.updatedAtUtc
          ? [
              {
                label: 'Rejection Date',
                description: format(new Date(request.updatedAtUtc), "MMMM do',' yyyy 'at' h:mm b"),
              },
              {
                label: 'Comment',
                description: request.comment ?? '',
              },
            ]
          : []),
        [Dto.AssetDepositStatus.Approved, Dto.AssetDepositStatus.ConfirmedByUser].includes(request.status) &&
          request.updatedAtUtc && {
            label: 'Approval Date',
            description: format(new Date(request.updatedAtUtc), "MMMM do',' yyyy 'at' h:mm b"),
          },
        [Dto.AssetDepositStatus.Completed].includes(request.status) &&
          request.updatedAtUtc && {
            label: 'Completion Date',
            description: format(new Date(request.updatedAtUtc), "MMMM do',' yyyy 'at' h:mm b"),
          },
      ].filter((v) => !!v) as Array<{
        label: string;
        description: string | Array<string> | React.ReactElement;
      }>,
      metaInfo: request.project
        ? ([
            request.project.name
              ? {
                  label: 'Project',
                  description: [request.project.name],
                }
              : null,
            request.project.status
              ? {
                  label: 'Project Status',
                  description: [request.project.status],
                }
              : null,
            request.project.registryCode
              ? {
                  label: 'Registry Code',
                  description: request.project.registryCode,
                }
              : null,
            request.project.registryName
              ? {
                  label: 'Registry Name',
                  description: [request.project.registryName],
                }
              : null,
            request.vintageStartDate && request.vintageEndDate
              ? {
                  label: 'Vintage Period',
                  description: [
                    [
                      format(new Date(request.vintageStartDate), 'dd/MM/yyyy'),
                      format(new Date(request.vintageEndDate), 'dd/MM/yyyy'),
                    ].join(' ~ '),
                  ],
                }
              : null,
          ].filter((v) => !!v) as Array<{
            label: string;
            description: string | Array<string> | React.ReactElement;
          }>)
        : [],
    };
  }, [data, registryAccountRequisitesMap]);

  const areActionsEnabled = (data as any)?.data?.status === Dto.AssetDepositStatus.New;

  const onPressApproveDeposit = () => {
    setIsModalApproveRequestVisible(true);
  };
  const onPressRejectDeposit = () => {
    setIsModalRejectRequestVisible(true);
  };

  const onPressConfirmApproveDeposit = async () => {
    setIsConfirmingApprove(true);

    try {
      await approveDepositRequest({ requestId: id });
      showToast({
        variant: ToastVariant.Success,
        message: `Successfully approved deposit request #${id}!`,
      });
      setIsModalApproveRequestVisible(false);
      refetch();
    } catch {
      showToast({
        variant: ToastVariant.Danger,
        message: `Failed to approve deposit request #${id}! Please try again later`,
      });
    } finally {
      setIsConfirmingApprove(false);
    }
  };

  const onPressConfirmRejectRequest = async () => {
    setIsRejecting(true);

    try {
      await rejectDepositRequest({
        requestId: id as string,
        comment: rejectionReason,
      });
      setIsModalRejectRequestVisible(false);
      refetch();
      showToast({
        variant: ToastVariant.Success,
        message: `Successfully rejected deposit request`,
      });
    } catch {
      showToast({
        variant: ToastVariant.Danger,
        message: `Failed to reject deposit request #${id}! Please try again later`,
      });
    } finally {
      setIsRejecting(false);
    }
  };

  return (
    <>
      <PageDepositRequest
        onPressBack={() => history.goBack()}
        isLoading={isLoading}
        {...pageProps}
        onPressApprove={areActionsEnabled ? onPressApproveDeposit : undefined}
        onPressReject={areActionsEnabled ? onPressRejectDeposit : undefined}
      />
      <ModalApproveDepositRequest
        {...pageProps}
        onClose={() => setIsModalApproveRequestVisible(false)}
        onConfirm={onPressConfirmApproveDeposit}
        isConfirming={isConfirmingApprove}
        isVisible={isModalApproveRequestVisible}
      />
      <ModalRejectDepositRequest
        requestId={id}
        isRejecting={isRejecting}
        rejectionReason={rejectionReason}
        onChangeRejectionReason={setRejectionReason}
        onClose={() => setIsModalRejectRequestVisible(false)}
        onPressReject={onPressConfirmRejectRequest}
        isVisible={isModalRejectRequestVisible}
      />
    </>
  );
};
