import { useHistory } from 'react-router-dom';
import { Pagination } from 'refreshed-component/molecules/Pagination';

import { Card, CardVariant, Layer, styled, toSpacing } from '@aircarbon/ui';

import type { ProjectStatus } from '../../../../utils/ProjectStatus';
import { type FilteringOptions, SearchAndFilter } from '../../../SearchAndFilter';
import type { Project } from '../../utils/Project';
import { FilterTabs } from './components/FilterTabs';
import { ProjectCard } from './components/ProjectCard';

export interface ProjectsListFilters {
  status?: ProjectStatus;
  projectTypes?: Array<number>;
  ccbStandards?: Array<number>;
  sectoralScopes?: Array<number>;
  vintageYear?: {
    from?: number;
    to?: number;
  };
}

export interface ProjectsListFilteringOptions extends FilteringOptions {
  statuses: Array<{
    label: string;
    value: ProjectStatus | 'all';
  }>;
}

interface ProjectsListProps {
  projects: Array<Project>;
  totalProjectsCount: number;
  currentPage: number;
  pageSize: number;
  filters: ProjectsListFilters;
  filteringOptions: ProjectsListFilteringOptions;
  search: string;
  areProjectsLoading: boolean;
  areFilteringOptionsLoading: boolean;
  onSearch: (value: string) => void;
  onFilter: (filters: ProjectsListFilters) => void;
  onChangePagination: (currentPage: number, pageSize: number) => void;
}

export const ProjectsList: React.FC<ProjectsListProps> = (props) => {
  const history = useHistory();
  const {
    projects,
    totalProjectsCount,
    pageSize,
    currentPage,
    onSearch,
    onFilter,
    onChangePagination,
    areProjectsLoading,
    filters,
    search,
    filteringOptions,
    areFilteringOptionsLoading,
  } = props;

  const onPressTab = (value: ProjectStatus | 'all') => {
    if (value === 'all' && filters.status === undefined) {
      return;
    }

    const newFilters = { ...filters };

    if (value === 'all') {
      delete newFilters.status;
      onFilter(newFilters);

      return;
    }

    newFilters.status = value;
    onFilter(newFilters);
  };

  const onChangeFilters = (changedFilters: Omit<ProjectsListFilters, 'status'>) => {
    const newFilters: ProjectsListFilters = { ...changedFilters };

    if (filters.status !== undefined) {
      newFilters.status = filters.status;
    }

    onFilter(newFilters);
  };

  const onPressEditProject = (projectId: number) => {
    history.push({
      pathname: `/account/apx/my-projects/${projectId}/edit`,
    });
  };

  const onPressViewProject = (projectId: number) => {
    history.push({
      pathname: `/account/apx/my-projects/${projectId}`,
    });
  };

  const onPressMarketplace = (projectId: number) => {
    history.push({
      pathname: `/account/apx/marketplace/${projectId}/trade`,
    });
  };

  return (
    <Layer>
      <StyledProjectsListCard
        header={
          <FilterTabs
            items={filteringOptions.statuses}
            activeTab={filters.status !== undefined ? filters.status : 'all'}
            onPressTab={onPressTab}
          />
        }
        variant={CardVariant.Bordered}
      >
        <SearchAndFilter
          search={search}
          filters={filters}
          onSearch={onSearch}
          onChange={onChangeFilters}
          filteringOptions={filteringOptions}
          areFilteringOptionsLoading={areFilteringOptionsLoading}
        />
        {areProjectsLoading &&
          Array.from({ length: 4 }).map((_, index) => <ProjectCard.Loader key={`loader-${index}`} />)}
        {!areProjectsLoading &&
          projects.map((project) => (
            <ProjectCard
              {...project}
              key={project.id}
              onPressEdit={() => onPressEditProject(project.id)}
              onPressView={() => onPressViewProject(project.id)}
              onPressMarketplace={() => onPressMarketplace(project.id)}
            />
          ))}
        <Pagination total={totalProjectsCount} page={currentPage} pageSize={pageSize} onChange={onChangePagination} />
      </StyledProjectsListCard>
    </Layer>
  );
};

const StyledProjectsListCard = styled(Card)`
  margin: 0 24px;
  padding: 24px;
  gap: ${({ theme }) => toSpacing(theme)(8)};
`;
